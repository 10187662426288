import { Component, OnInit, Output, EventEmitter, Input, HostListener, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { JppService } from 'src/app/providers/jpp.service';
import { RegisterHelper } from './register.helper';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [RegisterHelper]
})
export class RegisterComponent implements OnInit {
  registrationForm: FormGroup;
  enableModal;
  showPassword = false;
  @Output() signIn = new EventEmitter();
  @Output() close = new EventEmitter();
  showConfPassword: boolean;
  apiInprogress: boolean;
  constructor(
    public fb: FormBuilder,
    private service: JppService,
    private helper: RegisterHelper,
    private router: Router) { }

  ngOnInit(): void {
    this.registrationForm = this.fb.group({
      fullName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      countryOrLocation: ['', [Validators.required]],
      mobileNumber: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
      agree: ['', [Validators.required]],
    },
      {
        validator: ConfirmedValidator('password', 'confirmPassword')
      });
    this.enableModal = (window.screen.width < 991) ? true : false;
  }

  register() {
    this.registrationForm.markAllAsTouched();
    console.log(JSON.stringify(this.registrationForm.value));
    if (!this.registrationForm.value.agree) {
      this.service.snackbar('Please agree to terms and conditions!');
      return;
    }
    if (this.registrationForm.valid) {
      const payload = this.helper.mapFormDataToServiceRegister(this.registrationForm.value);
      console.log(payload);
      if (this.apiInprogress) {
        return;
      }
      this.apiInprogress = true;
      this.service.register(payload).subscribe(res => {
        if (res) {
          this.apiInprogress = false;
          if (res['response']['status'] && res['response']['status'] === 404) {
            this.service.snackbar(res['response']['result']);
            return;
          }
          if (res['response']['status'] && res['response']['status'] === 403) {
            this.service.snackbar(res['response']['result']);
            return;
          }
          this.service.snackbar(res['response']['result']);
          this.signIn.emit({ to: 'verify', data: payload.email });
        }
      }, err => { this.apiInprogress = false; });
    }
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  toggleConfPassword() {
    this.showConfPassword = !this.showConfPassword;
  }

}

export function ConfirmedValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.passwordMatch) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ passwordMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}
