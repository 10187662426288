<div class="col-lg-12 mct-3 px-5 pxc-5 py-4 dashboard">
  <div class="col-lg-12 px-5 pxc-5">
    <div class="col-lg-12">
      <div class="card px-lg-4 py-4">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-1 my-auto col-6">
              <a id="back" (click)="back.emit()" class="back-action px-0 py-3"><img src="./assets/icons/arrow-back.svg"
                  width="10px" class="arrow-back" alt="arrow-back" id="arrow-back-icon"> <span class="pl-2">Back</span>
              </a>
            </div>
            <div class="col-lg-10 my-auto">
              <div class="title-blk text-center px-3 pxc-5 py-2 col-lg-12 px-0">
                <h1 class="pxc-5 mb-0" id="title{{_data?.id}}">{{reorderLabel?.title}}</h1>
              </div>
            </div>
            <div class="col-6 my-auto text-right col-lg-1">
              <span class="text-right">{{questionIndex+1}} of {{_data?.questions?.length}}</span>
            </div>
          </div>
        </div>
        <div class="col-lg-12 pb-3 px-lg-0">
          <hr class="mx-3" />
          <div class="col-lg-12 pxc-5 pb-5 content-blk">
            <div class="col-lg-12 pb-2 mr-auto">
              <p class="reorder-text" id="subtitle{{_data?.id}}">{{reorderLabel?.subtitle}}</p>
            </div>
            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-10 pl-0 {{!questionblkActive ? 'fadeIn':''}}" *ngIf="!questionblkActive">
                  <div class="row">
                    <div class="col-lg-5 pr-lg-0">
                      <p class="source-blk">Source</p>
                      <ul cdkDropList #sourceList="cdkDropList"
                        [cdkDropListData]="_data?.questions[questionIndex]?.source"
                        [cdkDropListConnectedTo]="targetList" class="item-list" (cdkDropListDropped)="drop($event)">
                        <li [cdkDragDisabled]="paragraph==''" (click)="activeSourceIndex=i;activeTargetIndex=null"
                          class="item-box" [ngClass]="{'active': activeSourceIndex == i &&paragraph!=''}"
                          *ngFor="let paragraph of _data?.questions[questionIndex]?.source;let i=index" cdkDrag>
                          {{paragraph?.data?.slice(0, 2)}} <i *ngIf="paragraph&&paragraph!=''"
                            class="fa fa-bars px-1"></i>
                          {{paragraph?.data?.slice(2, paragraph?.length)}}
                        </li>
                      </ul>
                    </div>
                    <div class="col-lg-1 text-center p-0 mr-c-h pt-3">
                      <span (click)="pushleft()">
                        <img class="c-pointer" src="./assets/icons/arrow-left.svg" alt="arrow-left" id="arrow-left" />
                      </span>
                      <span class="pl-1" (click)="pushright()">
                        <img class="c-pointer" src="./assets/icons/arrow-right.svg" alt="arrow-right"
                          id="arrow-right" />
                      </span>
                    </div>
                    <div class="col-lg-5 p-lg-0">
                      <div class="row">
                        <div class="col-lg-11 target-blk">
                          <p class="source-blk">Target</p>
                          <ul cdkDropList #targetList="cdkDropList"
                            [cdkDropListData]="_data?.questions[questionIndex]?.target"
                            [cdkDropListConnectedTo]="sourceList"
                            class="item-list"
                            [ngClass]="{
                              'reorderError': isError[questionIndex],
                              'successBlk': isSuccess[questionIndex]
                            }"
                            (cdkDropListDropped)="drop($event)" id="reordertargetblk{{questionIndex}}">
                            <li #targetlist [cdkDragDisabled]="paragraph==''"
                              (click)="activeTargetIndex=j;activeSourceIndex=null" class="item-box"
                              data-id="{{paragraph.id}}" [ngClass]="{
                              'active': (activeTargetIndex == j &&paragraph!=''),
                              'drag-none':paragraph==''}"
                              *ngFor="let paragraph of _data?.questions[questionIndex]?.target;let j=index" cdkDrag>
                              {{paragraph?.data?.slice(0, 2)}} <i *ngIf="paragraph&&paragraph!=''"
                                class="fa fa-bars px-1"></i>
                              {{paragraph?.data?.slice(2, paragraph?.length)}}
                            </li>
                          </ul>
                        </div>
                        <div class="col-lg-1 p-0 mr-c-h">
                          <p class="mb-1" (click)="pushTop()">
                            <img src="./assets/icons/arrow-left.svg" class="arrow-top c-pointer" alt="arrow-top"
                              id="arrow-top" />
                          </p>
                          <p class="mb-1" (click)="pushBottom()">
                            <img src="./assets/icons/arrow-right.svg" class="arrow-bottom c-pointer" alt="arrow-bottom"
                              id="arrow-bottom" />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="{{questionblkActive? 'col-lg-12':'col-lg-2 p-0'}}">
                  <app-question-list (updateQuestions)="setFlag($event)" (action)="changeQuestion($event)"
                    (filter)="filter($event)" [questions]="questions"
                    (questionblkActive)="questionblkActiveAction($event)" (index)="getIndex($event)"
                    [activequestion]="questionIndex">
                  </app-question-list>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 pr-4 {{!questionblkActive ? 'fadeIn':''}}" *ngIf="!questionblkActive">
            <div class="row">
              <div class="col-lg-7">
                <app-score-and-timer [pause]="pause" [startcounter]="counter" (count)="getCount($event)" [counter]="counterval"
                  [score]="score" [timeRecommended]="_data?.questions[questionIndex]?.question?.recommendtime">
                </app-score-and-timer>
              </div>
              <div class="col-lg-3 p-0 ml-auto my-auto">
                <div class="col-lg-9 px-2 ml-auto text-right">
                  <button class="btn ml-auto w-100  btn-primary" (click)="checkAns()"
                    id="btn">{{reorderLabel?.btn}}</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-10 pt-5 pb-4" *ngIf="showans">
            <app-correct-answer [answerlist]="_data?.questions[questionIndex]?.answer"></app-correct-answer>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
