<div class="col-lg-12 p-0">
  <nav class="nav-bar d-flex w-100">
    <img src="./assets/images/logo.png" class="logo-home" alt="logo" id="logo" width="150px" />
    <button class="navbar-toggler" data-target="#land-nav" data-toggle="collapse" aria-controls="my-nav"
      aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <ul class="home-btn-blk" id="land-nav">
      <li class="nav-item mr-3 ml-auto" #registerFormElement>
        <a class="btn btn-home" (click)="registerBtnClick()" id="registerbtn">Register</a>
        <div class="register-box" *ngIf="openRegister">
          <app-register (close)="openRegister=false" (signIn)="action($event)"></app-register>
        </div>
        <div class="register-box register-box-small" *ngIf="openVerify">
          <app-verify (close)="openVerify=false" (signIn)="action($event)" (verifyCode)="verifyCode($event)" [email]="actionData"></app-verify>
        </div>
      </li>
      <li class="nav-item mx-3 text-center" #loginFormElement>
        <a class="btn-home btn" (click)="openLogin=!openLogin;openRegister=false" id="signinbtn">Signin</a>
        <div class="login-box" *ngIf="openLogin">
          <app-login [loader]="apiInprogress" (signIn)="login($event)" [options]="options"
            (changeForm)="changeForm($event)" (close)="openLogin=false" (action)="forgotPassword()"></app-login>
        </div>
      </li>
    </ul>
  </nav>
  <section class="main flex-column">
    <div class="container my-auto">
      <div class="col-lg-12 text-center">
        <h1 class="text-white">
          Master Your PTE Exam Preparation with Sydney's Leading Mock Test Platform!<br />
        </h1>
        <p class="text-white">
          JMSPTE gives you the BEST PTE COACHING through passionate and dedicated experts who do everything possible to ensure You GET YOUR 7, 8 OR 9 BAND in Your Very First Attempt with us. We GUARANTEE 9 BAND for 6 question types, if you FOLLOW OUR STRATEGIES EXACTLY AS IT IS.
        </p>
        <a class="pt-5" routerLink="/home" [fragment]="'practiceBlk'">
          <img src="./assets/icons/arrow-down.svg" alt="arr-down-icon" class="swipe-down" width="50px" />
        </a>
      </div>
    </div>
    <div class="col-lg-12">


      <!-- hidding prv design -->
      <ul *ngIf="false">
        <li class="arrow">
          <span class="arrow-icon">
            <img src="./assets/icons/arrow-icon.png" width="25px" alt="arrow-icon" id="arrow-icon" />
          </span>
        </li>
        <li>
          <button class="btn btn-cyan" id="onlineLiveClasses">Online Live Classes</button>
        </li>
        <li>
          <button class="btn btn-purple" id="freeDemo">Book a Free Demo Class</button>
        </li>
        <li>
          <button class="btn btn-green" id="assessYourScore">Assess Your Score</button>
        </li>
        <li class="hexa-btn" #registerFormElement>
          <button class="btn-hex btn-hexa-primary" (click)="openRegister=!openRegister;openLogin=false;">
            <span class="btn-txt">
              Register
            </span>
          </button>

        </li>
        <li class="hexa-btn" #loginFormElement>
          <button class="btn-hex btn-hexa-secondary" (click)="openLogin=!openLogin;openRegister=false">
            <span class="btn-txt ptc-1">
              Sign In
            </span>
          </button>
          <div class="login-box" *ngIf="openLogin">
            <app-login [loader]="apiInprogress" (signIn)="login($event)" [options]="options"
              (changeForm)="changeForm($event)" (close)="openLogin=false" (action)="forgotPassword()"></app-login>
          </div>
        </li>
      </ul>
    </div>
  </section>
  <section class="py-3" id="practiceBlk" attr-id="practiceBlk">
    <div class="col-lg-12 px-lg-5 py-5">
      <div class="col-lg-12 px-lg-5 pxc-4 px-3 py-lg-5">
        <div class="row">
          <div class="col-lg-6">
            <h1 class="home-intro-content-title">Practice Makes Perfect!</h1>
            <p class="pt-4">
              Choose from a wide range of packages to help you with your personal PTE preparation. Register and try our
              PTE
              sample questions for free now! More professional options are available including our “Practice tests + 1
              on 1
              consultation value pack”!
              The AIL online score given practice test is formatted just like a real PTE Academic
              test. All questions and recordings are designed and produced by AIL teaching department and copyrighted by
              AIL.
              <br />
              <br />
              For the users of Scored Mock Test, a score report will be generated based on the PTE score guide and sent
              to
              test takers’ email in 3 to 4 business days after the test finished, so that students can have an idea of
              their
              strengths and weaknesses.
              <br />
              <br />
              Important! The material on this website is intended for educational purposes only. The results you get on
              the
              mock test cannot be used as a real PTE result for immigration purposes. Nevertheless, you may find these
              questions very helpful in your preparation of PTE exam.Choose from a wide range of packages to help you
              with your personal PTE preparation. Register and try our PTE sample questions for free now! More
              professional
              options are available including our “Practice tests + 1 on 1 consultation value pack”!
              <br />
              <br />
              The AIL online score given practice test is formatted just like a real PTE Academic test. All questions
              and
              recordings are designed and produced by AIL teaching department and copyrighted by AIL.
              <br />
              <br />
              For the users of Scored Mock Test, a score report will be generated based on the PTE score guide and sent
              to
              test takers’ email in 3 to 4 business days after the test finished, so that students can have an idea of
              their
              strengths and weaknesses.
            </p>
          </div>
          <div class="col-lg-6">
            <div class="col-lg-12 text-center pl-5">
              <img src="./assets/images/home-into.svg" id="home-intro-blk" alt="home-intro" class="home-intro-img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section *ngIf="false">
    <div class="col-lg-12 px-lg-5 py-5">
      <div class="col-lg-12 px-lg-5 pxc-4 px-3 py-lg-5">
        <div class="row px-lg-4">
          <div class="col-lg-6 mb-3 {{(i==0)? 'pr-lg-4':'pl-lg-4'}}" *ngFor="let card of homecardData;let i=index">
            <div class="card-blk px-4 py-4" [style.backgroundImage]="'url('+ card?.bgImg + ')'">
              <h4>{{card?.title}}</h4>
              <p class="pt-2 pb-2" [innerHTML]="card?.content|titlecase"></p>
              <button class="btn btn-more">{{card?.btnTitle}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
