<div [class]="enableModal ? 'modal show' :''" tabindex="-1" role="dialog">
  <div [class]="enableModal ? 'modal-dialog modal-lg' : ''" role="document">
    <div [class]="enableModal ?'modal-content' :''">
    <form [formGroup]="registrationForm" autocomplete="off">
      <div class="register-container" id="register-form">
        <span class="close-icon" (click)="close.emit()"></span>
        <div class="col-lg-12 register-card px-4 py-4">
          <div class="col-lg-12 py-1 px-1">
            <h4 class="text-white pb-2">Start Your PTE Success Story Here</h4>
            <div class="col-lg-12 px-2 ">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group mb-0">
                    <label for="fullName">Full name*</label>
                    <input type="text" class="form-control" id="fullName" name="fullName" formControlName="fullName">
                    <p
                      class="error-blk {{(registrationForm.hasError('required', 'fullName') && registrationForm.get('fullName').touched) ? 'mb-0':'mb-4'}}">
                      <span class="error"
                        *ngIf="registrationForm.hasError('required', 'fullName') && registrationForm.get('fullName').touched">Cannot
                        be
                        blank.</span>
                    </p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group mb-0">
                    <label for="email">Email*</label>
                    <input type="email" class="form-control" id="email" name="email" formControlName="email">
                    <p
                      class="error-blk {{((registrationForm.hasError('required', 'email') &&
                registrationForm.get('email').touched)||(!registrationForm.hasError('required', 'email') &&
                registrationForm.hasError('email', 'email') && registrationForm.get('email').touched)) ? 'mb-0':'mb-4'}}">
                      <span class="error"
                        *ngIf="registrationForm.hasError('required', 'email') && registrationForm.get('email').touched">Cannot
                        be
                        blank.</span>
                      <span class="error"
                        *ngIf="!registrationForm.hasError('required', 'email') && registrationForm.hasError('email', 'email') && registrationForm.get('email').touched">Invalid
                        email.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12 px-2 ">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group mb-0">
                    <label for="password">Password*</label>
                    <input type="{{showPassword ? 'text':'password'}}" class="form-control" id="password" name="password" formControlName="password">
                    <i class="fa fa-eye icon" id="showPassword" (click)="togglePassword()" *ngIf="!showPassword"></i>
                    <i class="fa fa-eye-slash icon" id="togglePassword" (click)="togglePassword()" *ngIf="showPassword"></i>
                    <p
                      class="error-blk {{(registrationForm.hasError('required', 'password') && registrationForm.get('password').touched) ? 'mb-0':'mb-4'}}">
                      <span class="error"
                        *ngIf="registrationForm.hasError('required', 'password') && registrationForm.get('password').touched">Cannot
                        be
                        blank.</span>
                    </p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group mb-0">
                    <label for="confirmPassword">Confirm password*</label>
                    <input type="{{showConfPassword?'text':'password'}}" class="form-control" id="confirmPassword" name="confirmPassword"
                      formControlName="confirmPassword">
                      <i class="fa fa-eye icon" id="showPassword" (click)="toggleConfPassword()" *ngIf="!showConfPassword"></i>
                      <i class="fa fa-eye-slash icon" id="togglePassword" (click)="toggleConfPassword()" *ngIf="showConfPassword"></i>
                    <p
                      class="error-blk {{((registrationForm.hasError('required', 'confirmPassword') && registrationForm.get('confirmPassword').touched)||
                      !registrationForm.hasError('required', 'confirmPassword') && registrationForm.hasError('passwordMatch', 'confirmPassword')) ? 'mb-0':'mb-4'}}">
                      <span class="error"
                        *ngIf="registrationForm.hasError('required', 'confirmPassword') && registrationForm.get('confirmPassword').touched">Cannot
                        be
                        blank.</span>
                        <span  class="error"
                        *ngIf="!registrationForm.hasError('required', 'confirmPassword') && registrationForm.hasError('passwordMatch', 'confirmPassword') ">Password and Confirm Password must be match.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12 px-2 ">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group mb-0">
                    <label for="country/location">Country/Location*</label>
                    <input type="text" class="form-control" id="country/location" name="countryOrLocation"
                      formControlName="countryOrLocation">
                    <p
                      class="error-blk {{(registrationForm.hasError('required', 'countryOrLocation') && registrationForm.get('countryOrLocation').touched) ? 'mb-0':'mb-4'}}">
                      <span class="error"
                        *ngIf="registrationForm.hasError('required', 'countryOrLocation') && registrationForm.get('countryOrLocation').touched">Cannot
                        be
                        blank.</span>
                    </p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group mb-0">
                    <label for="mobileNumber">Mobile number*</label>
                    <input type="tel" numbersOnly class="form-control" id="mobileNumber" name="mobileNumber"  maxlength="10"
                      formControlName="mobileNumber">
                    <p
                      class="error-blk {{(registrationForm.hasError('required', 'mobileNumber') && registrationForm.get('mobileNumber').touched) ? 'mb-0':'mb-4'}}">
                      <span class="error"
                        *ngIf="registrationForm.hasError('required', 'mobileNumber') && registrationForm.get('mobileNumber').touched">Cannot
                        be
                        blank.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12 terms-blk">
              <div class="row">
                <div class="col-lg-6 text-lg-left text-center pb-3 pb-lg-0">
                  <div class="col-lg-12 pt-2 px-0">
                    <input type="checkbox" formControlName="agree" name="agree" id="agree" /> <span class="text-white pl-3">
                      <label  class="agree-condition" for="agree">
                        I agree terms and conditions *
                      </label>
                    </span>
                  </div>
                </div>
                <div class="col-lg-6 px-lg-2">
                  <div class="row">
                    <div class="col-lg-12 text-lg-right text-center pb-2 pb-lg-0">
                      <button type="button"  *ngIf="!apiInprogress"  id="registerBtn" class="btn btn-more" (click)="register()">Register</button>
                      <button  type="button" id="registerBtn" class="btn btn-more" *ngIf="apiInprogress"><div class="loader"></div></button>
                    </div>
                    <div class="col-lg-12 pt-2 info-txt text-lg-right text-center">
                      <a class="text-white text-right">
                        Already registered? <span class="signIn" id="signInRegistrationForm" (click)="signIn.emit('login')">Sign in</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
</div>
